import { FC, useState, useEffect } from "react"
import React from "reactn"
import marked from "marked"
import { graphql } from "gatsby"
import Meta, { MetaProps } from "../components/Meta"
import PageHeader from "../components/PageHeader"
import Img, { ImgProps } from "../components/Img"
import config from "../config"
import Link, { LinkProps } from "../components/Link"
import Slider from "../components/Slider"
import { Project } from "../types"
import ActivityIndicator from "../components/ActivityIndicator"

interface ShowcaseProject {
  color: string,
  title: string,
  text: string,
  img: ImgProps,
  link: LinkProps
}

interface ProsjekterPageTemplateProps {
  showcase: ShowcaseProject[],
  menu: {
    title: string,
    text: string
  }
}

const ProsjekterPageTemplate: FC<ProsjekterPageTemplateProps> = ({
  showcase,
  menu
}) => {
  const [ projects, setProjects ] = useState<Project[]>([])
  const [ isLoading, setIsLoading ] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${config.apiUrl}/hovedsider/projects?src=bonum&sortBy=order`)
        if(!res.ok || !res.json) throw new Error("En feil oppstod")
        const data = await res.json()
        if(!data || !data.projects) throw new Error(data || "En feil oppstod")
        setProjects(data.projects)
      } catch(e) {
        console.error(e.message)
      }
      setIsLoading(false)
    })()
  }, [])

  return (
    <main id="index-page">
      <section id="fremhevet-prosjekter">
        <div className="w-full h-full bg-black">
          <Slider
            options={{
              autoplay: true
            }}
          >
            {showcase.map(({
              color,
              title,
              text,
              img,
              link
            }, index) => (
              <div
                key={index}
                className="relative"
              >
                <div className="z-20 absolute inset-0 w-full h-full flex items-center px-12">
                  <div className="mx-auto w-full max-w-lg text-white text-center">
                    <p className="title leading-tight sm:text-center">
                      {title}
                    </p>

                    <hr className="sep bg-white sm:mx-auto" />

                    <div
                      className="markdown mt-2 sm:text-center"
                      dangerouslySetInnerHTML={{
                        __html: marked(text)
                      }}
                    />

                    <Link
                      to={link.href}
                      className="btn-ghost light mt-8"
                    >
                      {link.text}
                    </Link>
                  </div>
                </div>

                <div
                  className="z-10 absolute inset-y-0 w-1/2 h-full opacity-75"
                  style={{
                    backgroundColor: color,
                    left: index % 2 === 0 ? "0" : "auto",
                    right: index % 2 !== 0 ? "0" : "auto"
                  }}
                />

                <div
                  className="z-10 absolute inset-y-0 w-1/2 h-full"
                  style={{
                    backgroundColor: color,
                    left: index % 2 !== 0 ? "0" : "auto",
                    right: index % 2 === 0 ? "0" : "auto"
                  }}
                />

                <Img
                  className="z-0 w-full object-cover object-center h-screen-3/4"
                  src={img.src}
                  alt={img.alt}
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section
        id="meny"
        className="px-6 relative pt-16"
      >
        <div className="max-w-4xl text-center mx-auto w-full">
          <h1 className="title">
            {menu.title}
          </h1>

          <hr className="sep" />

          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: marked(menu.text)
            }}
          />
        </div>

        <nav className="mx-auto max-w-6xl flex flex-wrap pt-16 pb-32 text-base xl:pb-0 md:hidden">
          {projects.map(({
            _id,
            title,
            url
          }) => (
            <div
              key={_id}
              className="w-1/5 pr-4 py-1 truncate xl:w-1/4 lg:w-1/3"
            >
              <Link
                to={url}
                className="text-black"
              >
                {title}
              </Link>
            </div>
          ))}
        </nav>
      </section>

      {isLoading && (
        <div className="relative h-80">
          <ActivityIndicator
            shown={true}
          >
            Laster inn prosjekter..
          </ActivityIndicator>
        </div>
      )}

      {projects.map(({
        slug,
        title,
        url,
        no,
        gallery,
        videoUrl
      }, index) => (
        <section
          id={slug}
          key={slug}
          className="text-center flex flex-col items-center justify-center pt-16"
        >
          <div className="w-full bg-black">
            <Slider options={{
              lazy: true
            }}>
              {gallery.map(({ url, alt }) => (
                <div
                  key={url}
                  className="swiper-lazy"
                >
                  <Img
                    className="w-full h-full object-cover object-center max-h-screen"
                    src={url}
                    alt={alt || `${title} - ${index}`}
                  />
                </div>
              ))}
            </Slider>
          </div>

          <div className="max-w-3xl mx-auto w-full mb-24 mt-16 px-6 lg:my-12">
            <h2 className="title">
              {title}
            </h2>

            <div
              className="markdown my-8"
              dangerouslySetInnerHTML={{
                __html: marked(no.description)
              }}
            />

            <div className="mx-auto">
              <Link
                className="btn-ghost"
                to={url}
              >
                Se mer
              </Link>

              {videoUrl && (
                <Link
                  className="ml-4 btn-ghost"
                  to={videoUrl}
                >
                  Se video
                </Link>
              )}
            </div>
          </div>
        </section>
      ))}

      <Link
        to="/prosjekter/#meny"
        className="z-10 fixed bottom-0 right-0 mb-8 mr-8 rounded bg-black p-2 hover:bg-gray-600"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.75)" }}
        title="Se alle prosjekter"
      >
        <img
          className="w-4"
          src="/up-chevron.svg"
          alt="Til toppen"
        />
      </Link>
    </main>
  )
}

interface ProsjekterPageProps {
  data: {
    markdownRemark: {
      frontmatter: ProsjekterPageTemplateProps & {
        meta: MetaProps
      }
    }
  }
}

const ProsjekterPage: React.FC<ProsjekterPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <Meta {...frontmatter.meta} />

      <PageHeader />

      <ProsjekterPageTemplate
        showcase={frontmatter.showcase}
        menu={frontmatter.menu}
      />
    </>
  )
}

export default ProsjekterPage

export const ProsjekterPageQuery = graphql`
  query ProsjekterPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        meta {
          title
          description
          css
          js
        }

        showcase {
          color
          title
          text
          img {
            src
            alt
          }
          link {
            href
            text
          }
        }

        menu {
          title
          text
        }

      }
    }
  }
`
